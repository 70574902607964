export default class Points {
  constructor(updates) {
    this.list = [];
    this.updates = updates;
  }

  initFromDB(done) {
    fetch('/points', {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => {
        res.forEach(p => {
          var pt = {
            id: p.id, x: p.x, y: p.y, color: p.color,
            text: p.text, dir: p.dir,
            createdAt: parseInt(p.createdat)};
          this.list.push(pt)
        });
        this.sort();
        done();
      });
  }

  add(point, done) {
    const self = this;
    const addPoint = {
      text: point.text,
      x: point.x,
      y: point.y,
      color: point.color,
      dir: point.dir,
      createdAt: Date.now()
    }
    fetch('/points/add', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(addPoint)
    }).then(res => res.json())
      .then(res => {
        addPoint.id = res;
        self.list = self.list.concat(addPoint);
        self.updates(`a:${addPoint.id}:${addPoint.x}:${addPoint.y}:${addPoint.dir}:${addPoint.text}:${addPoint.color}:${addPoint.createdAt}`);
        done();
      });
  }

  remove(id) {
    let point = this.find(id);
    let index = this.list.indexOf(point);
    if (index !== -1) {
      this.list.splice(index, 1);
      this.updates(`r:${id}`);
      fetch(`/points/delete/${id}`, {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(res => {
          //console.log(res);
        });
    }
  }

  updatePosition(id, deltaX, deltaY) {
    var circ = this.find(id);
    circ.x += deltaX;
    circ.y += deltaY;
    circ.createdAt = Date.now();
    this.updates(`p:${id}:${circ.x}:${circ.y}:${circ.createdAt}`);
    this.sort();

    fetch(`/points/update/${id}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(circ)
    }).then(res => res.json())
      .then(res => {
        //console.log(`point with id ${id} updated`, res);
      });
  }

  updateDir(id, dir) {
    var circ = this.find(id);
    circ.dir = dir;
    circ.createdAt = Date.now();
    this.updates(`d:${id}:${circ.dir}:${circ.createdAt}`);
    this.sort();

    fetch(`/points/update/${id}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(circ)
    }).then(res => res.json())
      .then(res => {
        //console.log(`point with id ${id} updated`, res);
      });
  }

  clear() {
    this.list = [];
    this.updates('clear');
    fetch(`/points/clear/`, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    }).then(res => res.json())
      .then(res => {
        //console.log(`point with id ${id} updated`, res);
      });
  }

  sort() {
    this.list.sort((a, b) => a.createdAt - b.createdAt);
  }

  find(id) {
    return this.list.find(p => p.id === id)
  }

  receiveUpdate(msg) {
    // parse the msg
    var strings = msg.split(':');
    if (strings.length == 5 && strings[0] === 'p') {
      // p id x y createdAt
      var circ = this.list.find(c => c.id == strings[1]);
      if (circ) {
        circ.x = parseInt(strings[2]);
        circ.y = parseInt(strings[3]);
        circ.createdAt = parseInt(strings[4]);
      }
      this.sort();
    } else if (strings.length === 4 && strings[0] === 'd') {
      // d id dir createdAt
      var circ = this.list.find(c => c.id == strings[1]);
      if (circ) {
        circ.dir = parseDir(strings[2]);
        circ.createdAt = parseInt(strings[3]);
      }
      this.sort();
    } else if (strings.length === 2 && strings[0] === 'r') {
      // r id
      var circ = this.list.find(c => c.id == strings[1]);
      var index = this.list.indexOf(circ);
      if (index !== -1) {
        this.list.splice(index, 1);
      }
    } else if (strings.length === 8 && strings[0] === 'a') {
      // a id x y dir text color createdAt
      const addPoint = {
        id: parseInt(strings[1]),
        x: parseInt(strings[2]),
        y: parseInt(strings[3]),
        dir: parseDir(strings[4]),
        text: strings[5],
        color: strings[6],
        createdAt: parseInt(strings[7]),
      }
      this.list.push(addPoint);
      this.sort();
    } else if (strings.length === 1 && strings[0] === 'clear') {
      this.list = [];
    }
  }

}

function parseDir(dir) {
  return dir == "null" ? null : parseInt(dir);
}