import React, { useEffect } from 'react';
import * as d3 from 'd3';

const CIRCLE_RADIUS = 40;
const NOSE_OVERLAP = 2;
const NOSE_LENGTH = 15;
const NOSE_WIDTH = 10;

function nosePoints(d) {
  var radius = CIRCLE_RADIUS;
  var dir = d.dir;
  if (dir === null) {
    dir = 90;
    radius = 0;
  }
  var nx = Math.cos((Math.PI / 180) * dir);
  var ny = Math.sin((Math.PI / 180) * dir);
  return '' +
    (d.x + (nx * (radius + NOSE_LENGTH))) + ',' +
    (d.y + (ny * (radius + NOSE_LENGTH))) + ' ' +
    (d.x + (nx * (radius - NOSE_OVERLAP)) + (ny * NOSE_WIDTH)) + ',' +
    (d.y + (ny * (radius - NOSE_OVERLAP)) - (nx * NOSE_WIDTH)) + ' ' +
    (d.x + (nx * (radius - NOSE_OVERLAP)) - (ny * NOSE_WIDTH)) + ',' +
    (d.y + (ny * (radius - NOSE_OVERLAP)) + (nx * NOSE_WIDTH));
};

export function adjustFocus(x, y, setMovingNose) {
  let hitShape = null;
  let hitNodeName = null;
  let hitNose = false;

  var findHit = function(d) {
    var otherBox = this.getBBox();
    var otherLeft = otherBox.x;
    var otherRight = otherBox.x + otherBox.width;
    var otherTop = otherBox.y;
    var otherBottom = otherBox.y + otherBox.height;
    var collideHoriz = x < otherRight && x > otherLeft;
    var collideVert = y < otherBottom && y > otherTop;
    if (collideHoriz && collideVert) {
      hitShape = d;
      hitNodeName = this.nodeName;
    }
  }

  d3.selectAll('circle').each(findHit);
  d3.selectAll('polygon').each(findHit);
  hitNose = hitNodeName === 'polygon';
  setMovingNose(hitNose);
  //console.log('hit', hitShape ? hitShape.id : null, hitNodeName);
  d3.selectAll('circle').attr('stroke', (d) => (d === hitShape) && !hitNose ? 'tomato' : d.color);
  d3.selectAll('polygon').attr('stroke', (d) => (d === hitShape) && hitNose ? 'tomato' : (d.dir === null ? 'white' : d.color));
  return hitShape;
}

export function calculateAngle(shape, x, y) {
  var hitNoseRadius2 = (y - shape.y) * (y - shape.y) + (x - shape.x) * (x - shape.x);
  if (hitNoseRadius2 > CIRCLE_RADIUS * CIRCLE_RADIUS / 2) {
    return Math.round(Math.atan2(y - shape.y, x - shape.x) * (180 / Math.PI));
  } else {
    return null;
  }
}

function textWidth(text) {
  return text ? text.length * 7 : 0;
}

export function inDoorway(hitX, hitY) {
  return hitX < 37 && hitY < 75;
}

export function repaintFocus(shape, x, y) {
  var exiting = inDoorway(x, y);

  var onExit = function (d) {
    if (exiting && (d.id === shape.id)) {
      return 'white';
    } else {
      return d.color;
    }
  };

  var onExitCircle = function (d) {
    if (d.id === shape.id) {
      return exiting ? 'white' : 'tomato';
    } else {
      return d.color;
    }
  };

  var onExitNose = function (d) {
    if (d.id === shape.id) {
      return exiting || d.dir === null ? 'white' : 'tomato';
    } else {
      return d.dir === null ? 'white' : d.color;
    }
  };

  d3.selectAll('circle')
    .attr('fill', onExit)
    .attr('stroke', onExitCircle);
  d3.selectAll('polygon')
    .attr('fill', onExit)
    .attr('stroke', onExitNose);
}

export function Canvas({ points, touchStart, touchEnd, touchMove, mouseMove, mouseDown, mouseUp }) {
  const ref = React.useRef();

  useEffect(() => {
    let svg = d3.select(ref.current)
      .attr("width", "100%")
      .attr("height", "600px");

    svg.append('image')
      .attr('xlink:href', '/door.png')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', '75px')
      .attr('width', '37px');
  }, [points]);

  useEffect(() => {
    const svg = d3.select(ref.current);
    var groups = svg.selectAll('g').data(points.list, d => d.id);

    // update
    groups.select('circle')
      .attr('cx', d => d.x)
      .attr('cy', d => d.y);
    groups.select('polygon')
      .attr('points', nosePoints)
      //.attr('stroke', d => d.dir === null ? 'white' : d.color);
    groups.select('text')
      .attr('dx', d => d.x - textWidth(d.text) / 2)
      .attr('dy', d => d.y + CIRCLE_RADIUS + 30)
      .text(d => d.text);

    //create
    var createdGroups = groups.enter().append('g')
      .attr('fill', d => d.color)
      //.attr('stroke', d => d.color)
      .attr('stroke-width', '2px');
    createdGroups.append('circle')
      .attr('r', CIRCLE_RADIUS)
      .attr('cx', d => d.x)
      .attr('cy', d => d.y);
    createdGroups.append('polygon')
      .attr('points', nosePoints)
      .attr('stroke', d => d.dir === null ? 'white' : d.color);
    createdGroups.append('text')
      .attr('dx', d => d.x - textWidth(d.text) / 2)
      .attr('dy', d => d.y + CIRCLE_RADIUS + 30)
      .text(d => d.text);

    // remove
    groups.exit().remove();
    groups.order();
  });

  return (
    <div id="ic-canvas">
      <svg ref={ref} onTouchStart={touchStart} onTouchEnd={touchEnd} onTouchMove={touchMove} onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={mouseUp} />
    </div>
  )
}