import { Canvas } from './Canvas';

export default function WithControls({
  points, repColor, repName, addRepresentative, repColorChange, repNameChange,
  touchStart, touchEnd, touchMove,
  mouseMove, mouseDown, mouseUp, keyPress, clear }) {
  return (
    <div className="chart">
      <h2>i Constellate</h2>{" "}
      <span title='Enter the name of the representative'>
        <input id='repText' type='text' value={repName} placeholder='name of rep...' onChange={repNameChange} onKeyPress={keyPress} />
      </span>{" "}
      <span title='Add a representative to the constellation with the specified name'>
        <input className='addRepButton' type='button' value='Add Representative' onClick={addRepresentative} />
      </span>{" "}
      <span title='Choose the color for the representative'>
        <input id='repColor' type='color' name='color' value={repColor} onChange={repColorChange} />
      </span>{" "}
      <span title='Remove all representatives from the constellation'>
        <input className='clearButton' type='button' value='Clear' onClick={clear}/>
      </span>
      <Canvas points={points} touchStart={touchStart} touchEnd={touchEnd} touchMove={touchMove}
        mouseMove={mouseMove} mouseDown={mouseDown} mouseUp={mouseUp} />
    </div>
  );
}